<template>
  <div class="mm-main-page">
    <Header
      is-erp
      hide-search
    />
    <slot />
    <GTM />
    <ModalsContainer />
  </div>
</template>

<script lang="ts" setup>
import GTM from 'shared/components/GTM.vue';
import Header from 'components/layouts/default/Header.vue';
import BasesService from 'services/basesManager.service';
import ModalManager from 'shared/services/modalManager.service';
import { IModalManager } from 'shared/models/modalManager.model';
import { useServerSsoAuth, useAuthModal } from 'composables/useAuthHelper';
import { useMainTitle } from 'composables/useMainTitle';
import { useRefreshBasketData } from 'composables/useRefreshBasketData';
import { useCheckInternalUser } from 'shared/composables/useCheckInternalUser';
import setAuthCookieHeaders from '../composables/setAuthCookieHeaders';

setAuthCookieHeaders();
await useDomainAuth();

const basesService = inject<BasesService>(BasesService.getServiceName());
const modalManager = process.client ? inject<IModalManager>(ModalManager.serviceName) : undefined;

useMainTitle();
await useSetServerUserInfo();
await useServerSsoAuth();
useSetClientUserInfo();
useRefreshBasketData();
useCheckInternalUser();

onBeforeMount(async () => {
  useAuthModal();
  await nextTick();
  basesService?.initBases(modalManager);
  await basesService?.getPopulation();
});

</script>

<style lang="scss" scoped>
.mm-main-page {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  &::after {
    content: '';
    width: 100%;
    height: 10px;
    flex: 0 0 1px;
  }

  &.overflow {
    overflow: hidden;
  }

  :deep(.mm-header) {
    position: relative;
    z-index: 99;
  }
}
</style>
